.library-node {
  padding: 5px;
  margin-bottom: 2px;
  background: #fff;
  border: 1px solid #4b4a4a;
  border-left: 5px solid #4b4a4a;
  border-radius: 3px;
  cursor: grab;

  .text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .text,
  .type {
    display: block;
  }
}

.sticky-top,
.sticky-bottom,
.sticky-tabs {
  background: var(--phoenix-navbar-top-bg-color);
}

.sticky-tabs {
  position: sticky;
  /* Same z-index as bootstrap-utilities / .sticky-top css class */
  z-index: 1030;
  top: 83px;
}

.node-library,
.mini-editor {
  overflow-y: auto;
  height: 100%;
}

.react-flow-container {
  height: calc(100vh - 310px);
}

.react-flow-wrapper-container {
  height: calc(100vh - 300px);
  overflow: hidden;
}

/* React flow editor */
.editor-node {
  padding: 5px;
  margin-bottom: 2px;
  background: #fff;
  border: 1px solid #4b4a4a;
  border-left: 5px solid #4b4a4a;
  border-radius: 3px;
  cursor: grab;
  min-width: 150px;

  &.completed {
    border-color: var(--phoenix-success);
  }

  &.failed {
    border-color: var(--phoenix-danger);
  }

  &.canceled,
  &.terminated {
    border-color: var(--phoenix-warning);
  }

  &.skipped {
    border-color: darkgrey;
  }

  &.prerunning,
  &.running {
    border-color: var(--phoenix-info);
  }

}

.editor-node-id {
  font-size: 0.75rem;
}

/* Handles styling */
.handles-container {
  display: flex;
  position: absolute;
  right: 0;
  flex-direction: column;
  top: 30px;
  text-align: right;
  justify-content: space-between;

  &.default {
    height: 100%;
    top: 0;
    flex-direction: unset;
    align-items: center;
  }
}

.handle-wrapper {
  position: relative;
}

.handle {
  width: 8px;
  height: 8px;
  background-color: gray;
  border-radius: 50%;

  &.activated {
    background-color: var(--phoenix-success);
  }
}

.handle-label {
  font-size: 0.5rem;
  position: relative;
  top: -2px;
}

.handle-label {
  font-size: 0.5rem;
  position: relative;
  top: -2px;
}

.handle-icon {
  max-width: 20px;
  height: 20px;
}

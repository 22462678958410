/* Overlay (Dimmed Background) */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent; /* Lighter overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* Prevents cut-off */
  }
  
  /* Modal Container */
  .date-picker-modal {
    display: flex;
    background: white;
    border-radius: 12px;
    overflow: hidden;
    max-width: 1000px; 
    min-width: 600px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  /* Sidebar */
  .date-picker-sidebar {
    width: 150px; 
    background: #f8f8f8;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  
  /* Sidebar Buttons */
  .range-btn {
    background: none;
    border: none;
    padding: 10px;
    text-align: left;
    cursor: pointer;
    font-size: 14px;
    color: black;
  }
  
  .range-btn:hover {
    background: #ddd;
  }
  
  /* Content Area */
  .date-picker-content {
    padding: 15px;
    flex: 1;
    width: 1200px;
  }
  
  /* Apply Button */
  .apply-btn {
    background: black;
    color: white;
    padding: 10px 20px;
    border-radius: 20px;
    margin-top: 10px;
    cursor: pointer;
    border: none;
    justify-self: center;
  }

  .button-container {
    display:flex;
    justify-content: center;
    align-items: center;
  }

  .set-range-btn{
    height: 100%;
    min-width: 150px;
    min-height: 33px;
    border:1px solid var(--phoenix-input-border-color);
    background-color: transparent;
    border-radius: 4px;
    background-color: white;
  }

  .set-range-btn:hover{
    color:white;
    background-color: rgba(0, 0, 0, 0.2);
  }
  